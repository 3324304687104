import * as React from 'react';
import { AuthContext } from '../contexts/auth';

// axios
import axios from 'axios';

// bootstrap
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { Badge, Col, Row, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// icons
import { TbDownload, TbLink } from 'react-icons/tb';
import { IoCopyOutline } from 'react-icons/io5';
import {
  RiArrowRightSLine,
  RiDeleteBin6Line,
  RiEdit2Line,
  RiLogoutCircleRLine
  // RiUserLine
  // RiUserLine
} from 'react-icons/ri';
import { CgDuplicate } from 'react-icons/cg';
import { AiOutlineInfo } from 'react-icons/ai';
import { FiSun, FiMoon, FiExternalLink } from 'react-icons/fi';

// useful
import debounce, { debounceSpeed } from '../utils/debounce';
import host from '../utils/host';
import slugify from '../utils/slugify';

// assets
import logo from '../assets/img/logo.svg';
import ModalExplanation from '../components/ModalExplanation';

// components
import ModalPredefinedCommands from '../components/ModalPredefinedCommands';
import ModalLogin from '../components/ModalLogin';
import ModalManageCommands from '../components/ModalManageCommands';
import _ from 'lodash';
import { SortableItem, SortableList } from '@thaddeusjiang/react-sortable-list';
import DragHandler from '../components/DragHandler';
import NotificationAlert from '../components/NotificationAlert';
import { IoMdCheckmark } from 'react-icons/io';
import OverlayPresentation from '../components/OverlayPresentation';

const CopyCommands = () => {
  const { globaluser, setglobaluser } = React.useContext(AuthContext);

  axios.defaults.headers.common['x-access-token'] = localStorage.getItem('token');

  const [loading, setloading] = React.useState(true);
  const [darkmode, setDarkmode] = React.useState(Boolean(localStorage.getItem('darkMode')));
  const [alertNotification, setalertNotification] = React.useState({});

  const [commands, setCommands] = React.useState([]);
  const [command, setCommand] = React.useState({});
  const [Whatisit, setWhatisit] = React.useState(false);
  const [login, setlogin] = React.useState(false);
  const [PredefinedModal, setPredefinedModal] = React.useState(false);

  const [importJson, setimportJson] = React.useState('');
  const [SpecificBlocksBool, setSpecificBlocksBool] = React.useState(false);
  const [SpecificBlocks, setSpecificBlocks] = React.useState([]);

  const [currentBlock, setCurrentBlock] = React.useState({});
  const [currentBlockList, setCurrentBlockList] = React.useState([]);

  const [placement] = React.useState('bottom');
  const [modalShow, setmodalShow] = React.useState(false);

  const [isShowingAlert, setShowingAlert] = React.useState(true);

  const copy = (that) => {
    navigator.clipboard.writeText(that);
  };

  const handleList = (items) => {
    if (items) {
      const splittedItems = items.split(',');
      return splittedItems.map((item) => {
        return {
          command: item
        };
      });
    }
  };

  const handleDeleteItem = (blockIndex, blockName) => {
    if (window.confirm(`Are you sure you want to delete block of commands "${blockName}"?`)) {
      if (blockIndex > -1 && blockName) {
        let array = commands;
        array = array.filter((element, index) => index !== blockIndex);
        const arraySpecific = SpecificBlocks.filter((element) => element !== String(blockIndex));
        setCommands(array);
        setSpecificBlocks(arraySpecific);
      } else {
        alert("Couldn't remove the items");
      }
    }
  };

  const handleDuplicate = (blockIndex, blockName) => {
    const handleCopyNames = (name) => {
      const com = commands.filter((item) => item.title.includes(`${name} copy`));
      if (com.length > 0) {
        return `${name} copy ${com.length + 1}`;
      }
      return `${name} copy`;
    };
    setCommands([
      ...commands,
      {
        list: commands[blockIndex].list,
        title: handleCopyNames(blockName),
        id: `${slugify(handleCopyNames(blockName))}-${Math.random()}`
      }
    ]);
  };

  const handleExport = () => {
    let dataStr;
    if (SpecificBlocksBool) {
      const newCommands = [];
      commands.forEach((item, index) => {
        if (SpecificBlocks.includes(String(index))) {
          newCommands.push(item);
        }
      });
      dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(newCommands));
    } else {
      dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(commands));
    }
    const dlAnchorElem = document.getElementById('downloadAnchorElem');
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute('download', 'copycommands.json');
    dlAnchorElem.click();

    setTimeout(() => {
      setalertNotification({
        ...alertNotification,
        title: 'File exported successfully',
        type: 'success',
        visible: true,
        dateTime: new Date().toLocaleString()
      });
    }, 500);

    setSpecificBlocksBool(false);
    setSpecificBlocks([]);
  };

  const handleImport = () => {
    const importInfo = document.querySelector('[data-id="importInfo"]').files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const alertMsg = () => {
        setalertNotification({
          ...alertNotification,
          title: `File does not correspond to required structure. Make sure to follow the required format.`,
          type: 'danger',
          visible: true,
          dateTime: new Date().toLocaleString()
        });
      };
      if (event.target.result) {
        const importedFile = JSON.parse(event.target.result);
        if (importedFile?.length) {
          if (
            Object.prototype.hasOwnProperty.call(importedFile[0], 'id') &&
            Object.prototype.hasOwnProperty.call(importedFile[0], 'title') &&
            Object.prototype.hasOwnProperty.call(importedFile[0], 'list') &&
            Array.isArray(importedFile[0]['list'])
          ) {
            const newcommands = importedFile.map((el) => {
              return {
                ...el,
                id: `${el.id.split('-')[0]}-${Math.random()}`
              };
            });
            if (importJson === 'replace') {
              setCommands(newcommands);
            } else {
              setCommands([...commands, ...newcommands]);
            }
          } else {
            alertMsg();
          }
        } else {
          alertMsg();
        }
      }
    };
    reader.readAsText(importInfo);
  };

  const handleAdd = () => {
    if (command.title && command.list?.length > 0) {
      setCommands([
        ...commands,
        {
          title: command.title,
          list: command.list,
          id: `${slugify(command.title)}-${Math.random()}`
        }
      ]);

      setCommand({});

      document.querySelector('#input-title').value = '';
      document.querySelector('#input-commands').value = '';

      // scroll div down to find element just created
      const listOfCommands = document.getElementById('list-of-commands');
      listOfCommands.scrollTo({
        top: listOfCommands.scrollHeight,
        behavior: 'smooth'
      });

      // we need to wait a time for the element just created to show up
      setTimeout(() => {
        let accordionListDiv = document.querySelectorAll('.accordion-list > .d-flex');
        accordionListDiv = accordionListDiv[accordionListDiv.length - 1];
        accordionListDiv.querySelector('.accordion-button').classList.add('highlighted-accordion');
      }, 500);
    }
  };

  const deleteCommands = () => {
    if (window.confirm(`Are you sure you want to delete all blocks of commands?`)) {
      localStorage.removeItem('commands');
      setCommands([]);
    }
  };

  const handleSpecific = (e) => {
    if (!SpecificBlocks.includes(e.target.value) && e.target.checked) {
      setSpecificBlocks([...SpecificBlocks, e.target.value]);
    } else {
      setSpecificBlocks([...SpecificBlocks.filter((item) => item !== e.target.value)]);
    }
    e.stopPropagation();
  };

  const updateUserCommands = async (commands) => {
    await axios
      .patch(`${host}/user`, { ...globaluser, commands: commands })
      .then((response) => {
        if (response.data.status === 'ok') {
          setalertNotification({
            ...alertNotification,
            title: 'Commands updated successfully',
            type: 'success',
            visible: true,
            dateTime: new Date().toLocaleString()
          });
        } else {
          setalertNotification({
            ...alertNotification,
            title: 'Error trying to update fields',
            type: 'danger',
            visible: true,
            dateTime: new Date().toLocaleString()
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setalertNotification({
          ...alertNotification,
          title: 'Error trying to update fields',
          type: 'danger',
          visible: true
        });
      })
      .finally(() => {});
  };

  const verifyLogin = async () => {
    await axios
      .get(`${host}/verifylogin`)
      .then((response) => {
        if (response.data.user) {
          setglobaluser(response.data.user);
          if (response.data.user.commands.length > 0) {
            setCommands(JSON.parse(response.data.user.commands));
          } else {
            setCommands([]);
          }
        }
      })
      .catch(() => {
        setglobaluser({});
      })
      .finally(() => {
        setTimeout(() => {
          setloading(false);
        }, 1000);
      });
  };

  React.useEffect(() => {
    // verify login
    // verifyLogin();

    // set dark mode
    const getDarkMode = localStorage.getItem('darkMode');
    if (getDarkMode) {
      setDarkmode(Boolean(getDarkMode));
    }

    // fade presentation out
    setShowingAlert(false);
    // setTimeout(() => {
    // }, 100);
  }, []);

  React.useEffect(() => {
    if (_.isEmpty(globaluser)) {
      if (localStorage.getItem('commands')) {
        setCommands(JSON.parse(localStorage.getItem('commands')));
      }
    } else {
      if (globaluser.commands.length > 0) {
        setCommands(JSON.parse(globaluser.commands));
      } else {
        setCommands([]);
      }
    }

    // loading
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, [globaluser]);

  React.useEffect(() => {
    if (!_.isEmpty(globaluser)) {
      updateUserCommands(commands);
    } else {
      if (!!commands.length > 0) {
        localStorage.setItem('commands', JSON.stringify(commands));
      } else {
        localStorage.removeItem('commands');
      }
    }

    // console.log(commands);
    // eslint-disable-next-line
  }, [commands]);

  return (
    <React.Fragment>
      {/* HEADER */}
      <header className="main">
        <div className="d-flex justify-content-between align-items-center h-100">
          <div className="d-flex justify-content-between align-items-center">
            <img src={logo} alt="Logo" />
            <span className="separator"></span>
            <Button
              title="What is Copy Commands?"
              onClick={() => {
                setWhatisit(true);
              }}
              className="rounded-circle"
              variant="outline-primary"
              size="sm">
              <AiOutlineInfo />
            </Button>
            {/* <Button
              title="Sign Up - Sign In"
              onClick={() => {
                setlogin(true);
              }}
              className="rounded-circle mx-2"
              variant={`${_.isEmpty(globaluser) ? 'outline-' : ''}primary`}
              size="sm">
              <RiUserLine />
            </Button> */}
          </div>
          <div className="text-end d-flex align-items-center justify-content-center">
            <label title="Click to switch mode" className="mx-1">
              <input
                type="checkbox"
                className="darkModeInput"
                defaultChecked={darkmode}
                onClick={(e) => {
                  if (e.target.checked) {
                    localStorage.setItem('darkMode', 'true');
                    setDarkmode(true);
                  } else {
                    setDarkmode(false);
                    localStorage.removeItem('darkMode');
                  }
                }}
              />
              <div className="darkModeSwitch">
                <div className="d-flex h-100 px-2 align-items-center justify-content-between">
                  <FiMoon />
                  <FiSun />
                </div>
                <div className="bullet"></div>
              </div>
            </label>
            {!_.isEmpty(globaluser) && (
              <React.Fragment>
                <p className="p-helper">
                  <small>Logged in as</small>
                  <small className="help d-block">
                    <strong>{`${globaluser.first_name} ${globaluser.last_name}`}</strong>
                  </small>
                </p>
                <Button
                  title="Click to logout"
                  variant="outline-danger"
                  className="rounded-circle"
                  size="sm"
                  onClick={() => {
                    setloading(true);
                    localStorage.removeItem('token');
                    setglobaluser({});
                    setCommands([]);
                    setalertNotification({
                      ...alertNotification,
                      title: 'User logged out successfully',
                      type: 'alert',
                      visible: true,
                      dateTime: new Date().toLocaleString()
                    });
                  }}>
                  <RiLogoutCircleRLine />
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </header>

      {/* WRAPPER */}
      {/* <section className="wrapper"> */}
      <section className="wrapper" data-darkmode={darkmode}>
        <section>
          <Row>
            <Col xs={12} md={6} className="wrapper-left">
              <Row className="g-5 p-4">
                <Col xs={12} lg={6}>
                  <Modal.Dialog>
                    <Modal.Header>
                      <Modal.Title>Add a new block of commands</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      {/* <p>Add a new block of commands to the right side.</p> */}

                      <Form.Label>Block title</Form.Label>
                      <Form.Control
                        type="text"
                        id="input-title"
                        onChange={debounce((e) => {
                          setCommand({ ...command, title: e.target.value });
                        }, debounceSpeed)}
                      />
                      <Form.Text id="passwordHelpBlock" muted>
                        This is the title that will be on the accordion's header.
                      </Form.Text>

                      <Form.Group className="my-3">
                        <Form.Label>Insert commands, separated by comma.</Form.Label>
                        <Form.Control
                          className="font-code"
                          as="textarea"
                          placeholder="command 1, command 2, comm..."
                          rows={4}
                          id="input-commands"
                          onChange={debounce((e) => {
                            setCommand({
                              ...command,
                              list: handleList(e.target.value)
                            });
                          }, debounceSpeed)}
                        />
                      </Form.Group>

                      <Button
                        onClick={() => {
                          handleAdd();
                        }}
                        disabled={!command.title || !command.list}
                        className="w-100"
                        variant="primary"
                        type="submit">
                        Add block
                      </Button>
                      <p className="mt-2 small">
                        * Those commands will be saved on your{' '}
                        <strong className="text-danger">
                          {_.isEmpty(globaluser) ? 'Local Storage' : 'account'}
                        </strong>
                        .
                      </p>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
                <Col xs={12} lg={6}>
                  <Modal.Dialog>
                    <Modal.Header>
                      <Modal.Title>Export / Import</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="mt-2">
                      <p className="mb-1">
                        You can export a file containing the list of commands to import in another
                        browser.
                      </p>
                      <p className="text-primary mb-1 mt-3">
                        <small>Export .json file</small>
                      </p>
                      <Row className="my-1">
                        <Col>
                          <Form.Check
                            checked={SpecificBlocksBool}
                            disabled={!commands.length}
                            onChange={() => {
                              setSpecificBlocksBool(!SpecificBlocksBool);
                            }}
                            type="checkbox"
                            label="Export specific groups"
                            name="export-specific"
                            id="export-specific"
                          />
                        </Col>
                      </Row>
                      <OverlayTrigger
                        key="export"
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            <p className="mb-0 text-warning small">
                              <small>What will be exported:</small>
                            </p>
                            <pre
                              style={{ fontSize: '10px', lineHeight: '12px' }}
                              className="p-0 m-0 text-start">
                              <ul>
                                {commands?.map((element, index) => {
                                  if (SpecificBlocksBool) {
                                    if (SpecificBlocks.includes(String(index))) {
                                      return (
                                        <React.Fragment key={index}>
                                          <li>{element.title}</li>
                                        </React.Fragment>
                                      );
                                    }
                                  } else {
                                    return (
                                      <React.Fragment key={index}>
                                        <li>{element.title}</li>
                                      </React.Fragment>
                                    );
                                  }
                                  return '';
                                })}
                              </ul>
                            </pre>
                          </Tooltip>
                        }>
                        <Button
                          disabled={
                            !commands.length || (SpecificBlocksBool && !SpecificBlocks.length)
                          }
                          onClick={() => {
                            handleExport();
                          }}
                          className="w-100"
                          variant="outline-primary"
                          type="submit">
                          Export (.json)
                          <TbDownload className="mx-2" />
                        </Button>
                      </OverlayTrigger>
                      {/* helper to download file */}
                      <a href="#" id="downloadAnchorElem" style={{ display: 'none' }}>
                        Export file
                      </a>

                      <hr />

                      <p className="text-primary mb-1 mt-4">
                        <small>Import .json file</small>
                      </p>
                      <Row className="my-1">
                        <Col>
                          <Form.Check
                            onChange={(e) => {
                              setimportJson(e.target.value);
                            }}
                            type="radio"
                            label="Replace data"
                            value="replace"
                            name="import-json"
                            id="import-json"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            onChange={(e) => {
                              setimportJson(e.target.value);
                            }}
                            type="radio"
                            label="Append data"
                            value="append"
                            name="import-json"
                            id="import-json2"
                          />
                        </Col>
                      </Row>

                      <Form.Group>
                        <Form.Control
                          disabled={!importJson}
                          accept=".json"
                          data-id="importInfo"
                          type="file"
                          onClick={(event) => (event.target.value = null)}
                          onChange={() => {
                            handleImport();
                          }}
                        />
                      </Form.Group>
                    </Modal.Body>
                  </Modal.Dialog>
                </Col>
              </Row>
              <hr />
              <p className="px-4">
                If this app helps you, consider helping me keeping it on air, by{' '}
                <strong>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.paypal.com/donate/?hosted_button_id=MMWB2XULZH7LE">
                    making a donation
                  </a>
                </strong>
                .
              </p>
              <p className="px-4 small">
                Created and maintained by{' '}
                <a href="mailto:jeanmichellsouber@gmail.com">jeanmichellsouber@gmail.com</a>
              </p>
            </Col>
            <Col xs={12} md={6} className="p-4 right-side position-relative" id="list-of-commands">
              {loading ? (
                <div
                  className="spinnerWrapper"
                  style={{
                    background: 'rgba(0,0,0,0.05)',
                    borderRadius: '0px'
                  }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <React.Fragment>
                  <p className="mb-0">
                    Listing commands from:{' '}
                    <strong className="text-primary">
                      {_.isEmpty(globaluser) ? 'Local Storage' : globaluser.email}
                    </strong>
                    .
                  </p>
                  {/* {_.isEmpty(globaluser) && (
                    <small className="d-block opacity-50">
                      Create an account to have them wherever.
                    </small>
                  )} */}
                  <Accordion defaultActiveKey="0" className="mt-3 h-100 accordion-list">
                    {commands.length ? (
                      <React.Fragment>
                        <SortableList items={commands} setItems={setCommands}>
                          {({ items }) =>
                            items.map((el, index) => {
                              return (
                                <SortableItem
                                  className="d-flex"
                                  key={el.id}
                                  id={el.id}
                                  DragHandler={DragHandler}>
                                  <Accordion.Item id={el.id} eventKey={el.id} key={el.id}>
                                    <Accordion.Header>
                                      <div className="w-100 d-flex justify-content-between align-items-center">
                                        {/* {SpecificBlocksBool && ( */}
                                        <Form.Check
                                          onClick={(e) => {
                                            handleSpecific(e);
                                          }}
                                          type="checkbox"
                                          label=""
                                          value={index}
                                          name="export-specific"
                                          id={`export-specific-${index}-${slugify(el.title)}`}
                                          className="m-0"
                                          disabled={!SpecificBlocksBool}
                                          style={{ display: SpecificBlocksBool ? 'block' : 'none' }}
                                        />
                                        {/* )} */}
                                        <span style={{ marginRight: 'auto' }}>{el.title}</span>
                                        <div className="d-flex mx-3">
                                          <a
                                            href="#"
                                            title={`Duplicate "${el.title}"`}
                                            className="btn btn-outline-primary btn-sm manage-link mx-3 d-flex"
                                            size="sm"
                                            onClick={(e) => {
                                              handleDuplicate(index, el.title);
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}>
                                            <CgDuplicate className="mx-1" />
                                          </a>
                                          <a
                                            href="#"
                                            title={`Edit "${el.title}"`}
                                            className="mx-1 d-flex btn btn-outline-primary btn-sm manage-link"
                                            size="sm"
                                            onClick={(e) => {
                                              setmodalShow(true);
                                              setCurrentBlock({
                                                ...commands[index],
                                                id: index
                                              });
                                              setCurrentBlockList([
                                                ...commands[index].list.map((item, index) => {
                                                  return {
                                                    id: `${index}`,
                                                    name: `${item.command ? item.command : item}`,
                                                    hint: `${item.hint ? item.hint : ''}`,
                                                    hidden: item.hidden ? true : false,
                                                    link: item.link ? true : false
                                                  };
                                                })
                                              ]);
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}>
                                            <RiEdit2Line className="mx-1" />
                                          </a>
                                          <a
                                            href="#"
                                            title={`Remove "${el.title}"`}
                                            className="mx-1 d-flex btn btn-outline-danger btn-sm manage-link"
                                            size="sm"
                                            // variant="outline-danger"
                                            onClick={(e) => {
                                              handleDeleteItem(index, el.title);
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}>
                                            <RiDeleteBin6Line className="mx-1" />
                                          </a>
                                        </div>
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="limited-height">
                                      <ul>
                                        {el.list?.map((el, index) => {
                                          return (
                                            <li className="command-li my-2" key={index}>
                                              <Button
                                                onClick={(e) => {
                                                  // workaround for old values
                                                  const commandVar =
                                                    el.command !== undefined ? el.command : el;
                                                  if (el.link) {
                                                    window.open(el.command, '_blank');
                                                  } else {
                                                    copy(commandVar);
                                                    e.target.classList.add('--copied');
                                                  }
                                                }}
                                                onMouseLeave={(e) => {
                                                  e.target.classList.remove('--copied');
                                                }}
                                                className="w-100 position-relative"
                                                variant="light">
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <div className="texts-wrapper d-flex align-items-end">
                                                    {el.link ? <TbLink /> : <RiArrowRightSLine />}
                                                    <div>
                                                      {el.hint && (
                                                        <small className="explanation d-block">
                                                          {el.hint}
                                                        </small>
                                                      )}
                                                      <code
                                                        className={`font-code ${
                                                          el.link ? '--link-style' : ''
                                                        }`}>
                                                        {el.command !== undefined
                                                          ? el.hidden
                                                            ? '**********'
                                                            : el.command
                                                          : el}
                                                      </code>
                                                    </div>
                                                  </div>
                                                  {el.link ? (
                                                    <div className="position-relative">
                                                      <Badge
                                                        pill
                                                        bg="primary"
                                                        className="position-absolute">
                                                        <FiExternalLink />
                                                        <span className="mx-1 float-right">
                                                          open
                                                        </span>
                                                      </Badge>
                                                    </div>
                                                  ) : (
                                                    <div className="position-relative">
                                                      <Badge
                                                        pill
                                                        bg="primary"
                                                        className="copy position-absolute">
                                                        <IoCopyOutline />
                                                        <span className="mx-1 float-right">
                                                          copy
                                                        </span>
                                                      </Badge>
                                                      <Badge
                                                        pill
                                                        className="copied yellow_bg position-absolute">
                                                        <IoMdCheckmark />
                                                        <span className="mx-1 float-right">
                                                          copied
                                                        </span>
                                                      </Badge>
                                                    </div>
                                                  )}
                                                </div>
                                              </Button>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </SortableItem>
                              );
                            })
                          }
                        </SortableList>
                        <Button
                          onClick={() => {
                            deleteCommands();
                          }}
                          className="w-100 my-3"
                          variant="outline-danger">
                          Delete all blocks
                        </Button>
                      </React.Fragment>
                    ) : (
                      <div className="centered-item h-100">
                        <p className="text-danger mb-0">
                          <strong>There are no blocks of commands to show.</strong>
                        </p>
                        <p>Add a new one on the left side.</p>
                        <p>
                          Or you can start by{' '}
                          <a
                            href="#"
                            onClick={() => {
                              setPredefinedModal(true);
                              return false;
                            }}>
                            adding some predefined groups of commands
                          </a>
                          .
                        </p>
                      </div>
                    )}
                  </Accordion>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </section>
      </section>

      <NotificationAlert
        text={alertNotification.title}
        type={alertNotification.type}
        visible={alertNotification.visible}
        dateTime={alertNotification.dateTime}
      />

      {/* MODAL FOR MANAGING COMMANDS */}
      <ModalManageCommands
        modalShow={modalShow}
        setmodalShow={setmodalShow}
        commands={commands}
        setCommands={setCommands}
        currentBlock={currentBlock}
        setCurrentBlock={setCurrentBlock}
        currentBlockList={currentBlockList}
        setCurrentBlockList={setCurrentBlockList}
      />

      {/* modal what is it */}
      <ModalExplanation Whatisit={Whatisit} setWhatisit={setWhatisit} />

      {/* modal login */}
      <ModalLogin
        verifyLogin={verifyLogin}
        loading={loading}
        setloading={setloading}
        login={login}
        setlogin={setlogin}
      />

      {/* modal predefined */}
      <ModalPredefinedCommands
        commands={commands}
        setCommands={setCommands}
        PredefinedModal={PredefinedModal}
        setPredefinedModal={setPredefinedModal}
      />

      <OverlayPresentation
        className={`${isShowingAlert ? '' : '--hidden'}`}
        onTransitionEnd={() => {}}
      />
    </React.Fragment>
  );
};

export default CopyCommands;
